var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"6a4fff537ceee166693d6d6d01e02186d95f1e4e"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from "@sentry/nextjs";
import Cohere from "cohere-js";
import CohereSentry from "cohere-sentry";
import posthog from "posthog-js";

const DEV = process.env.NODE_ENV === "development";
const SENTRY_DSN =
  process.env.SENTRY_DSN ||
  process.env.NEXT_PUBLIC_SENTRY_DSN ||
  "https://7081bb908b264dd88bf37e8387025d9f@o427213.ingest.sentry.io/6246221";
const COHERE_API_KEY = process.env.NEXT_PUBLIC_COHERE_API_KEY;
if (COHERE_API_KEY) {
  Cohere.init(COHERE_API_KEY, { segmentIntegration: true });
}

Sentry.init({
  // Disabled in dev mode
  dsn: DEV ? null : SENTRY_DSN,
  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate: 1.0,

  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
  integrations: [
    new CohereSentry(),
    new posthog.SentryIntegration(posthog, "humanloop", "6246221"),
  ],
  ignoreErrors: [
    "ResizeObserver loop completed with undelivered notifications.",
  ],
});

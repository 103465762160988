import "@styles/globals.css";
import "@styles/prism.css";
import "katex/dist/katex.css";

import { ClientReload } from "@components/blog/ClientReload";
import { Analytics } from "@vercel/analytics/react";
import { useEffect } from "react";
import TagManager from "react-gtm-module";
import { Toaster } from "react-hot-toast";
import { useAnalytics } from "services/analytics.service";
import localFont from "next/font/local";

// Exposes Inter font under `--font-inter` CSS variable
// See: https://nextjs.org/docs/app/building-your-application/optimizing/fonts#local-fonts
// const inter = localFont({
//   src: "../fonts/Inter.var.woff2",
//   variable: "--font-inter",
// });

const neueHaasDisplay = localFont({
  src: [
    {
      path: "../fonts/NHaasGroteskDSPro45Lt/font.woff2",
      weight: "300",
      style: "normal",
    },
    {
      path: "../fonts/NHaasGroteskDSPro46LtIt/font.woff2",
      weight: "300",
      style: "italic",
    },
    {
      path: "../fonts/NHaasGroteskDSPro65Md/font.woff2",
      weight: "bold",
      style: "normal",
    },
    {
      path: "../fonts/NHaasGroteskDSPro66MdIt/font.woff2",
      weight: "bold",
      style: "italic",
    },
    {
      path: "../fonts/NHaasGroteskDSPro55Rg/font.woff2",
      weight: "normal",
      style: "normal",
    },
    {
      path: "../fonts/NHaasGroteskDSPro56It/font.woff2",
      weight: "normal",
      style: "italic",
    },
  ],
  variable: "--font-neue-haas-display",
});

const isDevelopment = process.env.NODE_ENV === "development";
const isSocket = process.env.SOCKET;

export default function Website({
  Component,
  pageProps,
}: NextPageTypes.AppPropsWithLayout) {
  useAnalytics();
  useEffect(() => {
    TagManager.initialize({
      gtmId: process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER as string,
    });
  }, []);
  const getLayout = Component.getLayout || ((page) => page);
  return (
    <>
      {isDevelopment && isSocket && <ClientReload />}

      {/* Defines --font-inter and --font-neue-haas-display */}
      <main
        // TODO: We want to use `${inter.variable}` here, but it renders too bold in Safari (for weights 600 and above)
        className={`${neueHaasDisplay.variable} font-sans`}
      >
        {getLayout(<Component {...pageProps} />)}
      </main>
      <Toaster position="bottom-right" />

      <Analytics />
    </>
  );
}

// Analytics

// Our analytics strategy is the following.
//
// Most analytics events are sent from the backend to Segment. We do this
// on the backend to make it reliable without risk of losing events due to
// ad blockers etc.
//
// On the frontend we still have some analytics so that we can track page views
// and other events that happen only the client, or events that happen when the
// user does not have an account. The client-side libraries will get blocked by
// ad-blockers (losing maybe 30% of events) so we'll try to proxy the requests
// where possible. The benefit of client-side tracking is that you get a lot of
// benefits for free. They'll automatically create anonymous IDs for un-identified users,
// track their path through the app and then join them to a user
// when they sign in. They may also automatically capture other client characteristics
// like user journey replays, console logs, all clicks etc. (depending on the library)
//
// We're primarily using Segment which feeds to Mixpanel (and elsewhere) but our plan
// at one point was to use PostHog for everything. We may abandon this plan, primarily
// because of perceived product quality with PH (not super easy to use/fully-featured
//  product analytics, and even more half-baked other features like flags, and replays).
// We use the PostHog library directly because that had many client-side benefits not
// available via Segment like event-autocapture. This may change still.
//
// So:
// - Backend sends all meaningful events to Segment
// - Frontend tracks pageviews, anonymous users, and interesting client-only events
// - Frontend calls identify() to join the anon user to their account
// - Backend calls identify() to supply the user traits and join them to their organization
// - Frontend calls reset() to clear the cookies when the user logs out

// WEBSITE ONLY DIFFERENCES
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
// The only difference between this and frontend is the inclusion of Google Tag Manager.
// This was added a year ago when experimenting with Google ads, in a desperate attempt to
// finally get it to track a conversion event. It did work, but the experience was terrible
// as it took ~24 hours to see if the data was coming through.
// It would be nice if this code was removed.

import { AnalyticsBrowser } from "@segment/analytics-next";
import { useRouter } from "next/router";
import posthog from "posthog-js";
import { useEffect } from "react";
import TagManager from "react-gtm-module";

// Constants to follow our object-action-properties naming convention
// https://humanloop.notion.site/Analytics-Naming-Convention-c3409da767c64a4497d6eb213e3a446e

const SEGMENT_KEY = process.env.NEXT_PUBLIC_SEGMENT_KEY;
const POSTHOG_KEY = process.env.NEXT_PUBLIC_POSTHOG_KEY;
const NODE_ENV = process.env.NODE_ENV;
const LOGGING = false;

const segment = AnalyticsBrowser.load(
  {
    writeKey: SEGMENT_KEY || "",
    // Proxy the CDN
    // GET http://cdn.segment.com/v1/projects/<writekey>/settings -> /cdn/v1/project/<writekey>/settings
    // GET https://cdn.segment.com/next-integrations/actions/...js -> /cdn/next-integrations/actions/...js
    cdnURL: "/cdn",
  },
  {
    integrations: {
      "Segment.io": {
        // https://api.segment.io/v1/t -> https://app.humanloop.com/edn/t
        apiHost: "app.humanloop.com/edn",
        protocol: "https",
      },
    },
    // Don't believe this does anything for our set up but just in case.
    obfuscate: true,
  },
);

export const useAnalytics = () => {
  const router = useRouter();

  // Initialise PostHog
  useEffect(() => {
    if (POSTHOG_KEY) {
      posthog.init(POSTHOG_KEY, {
        // humanloop.com/ingest is a nextjs rewrite to app.posthog.com, acting
        // as a proxy so we don't lose data to ad blockers.
        api_host: "/ingest",
        opt_in_site_apps: true,
        person_profiles: "identified_only",
      });
    } else {
      console.log("PostHog key not found. Not enabled.");
    }
  }, []);

  // Track page views
  useEffect(() => {
    // Track the initial page load
    analytics.page();

    // Track page views
    const handleRouteChange = () => analytics.page();

    router.events.on("routeChangeComplete", handleRouteChange);
    return () => {
      router.events.off("routeChangeComplete", handleRouteChange);
    };
  }, [router.events]);
};

/*
  Our analytics events are primarily sent in the backend.
  Only use client-side events for the few events that are interesting and
  happen client-side only.

  Make sure that the same naming convention is followed. 
  Generally Noun past-tense Verb in title case.
  See `AnalyticsEvent` enum in backend in 
  `src/external/app/routers/analytics/dependency.py`
  
  Keep the Nouns and Noun-Verbs to a minumum. Use a semantic group 
  if available and fallbackto the literal objects (e.g. Button) if 
  that has its challenges.

  Some of the best guidance is at June e.g.:
  https://help.june.so/en/articles/6817174-new-event-vs-new-property
*/
export type AnalyticsEvent =
  | "Demo Requested" // A separate event rather than property as a key conversion event
  | "Button Clicked"
  | "Video Played"
  | "Form Submitted"
  | "Offer Claimed";

export enum EventPage {
  Home = "home",
  Pricing = "pricing",
  Customers = "customers",
  Customer = "customer",
  Blog = "blog",
  BlogPost = "blog post",
  Debug = "debug",
}

export enum EventSection {
  Header = "header",
  Hero = "hero",
  TechStack = "tech stack",
  Comparison = "comparison",
  Developer = "developer",
  Enterprise = "enterprise",
  GetStarted = "get started",
  FinalCTA = "final cta",
  Footer = "footer",
  Products = "products",
}

export enum EventPurpose {
  Login = "login",
  Sales = "sales",
  TrustReport = "trust report",
  Signup = "signup",
  Discord = "discord",
  Documentation = "documentation",
  FreeProduct = "free",
}

/**
 * The context object that is attached to every event.
 *
 * This expands on what Segment will add by default, with
 * the context of what application the event is coming from.
 *
 * This should play well with the context objects we send in
 * `src/external/app/routers/analytics/dependency.py`
 *
 * See: https://segment.com/docs/connections/spec/common/#context
 */
const appContext = {
  app: {
    name: "hl-alpha-website",
  },
};

const identify = (userId?: string, traits?: any) => {
  LOGGING && console.info("identify", userId, traits);
  // Segment can handle undefined userId if we just wish to add traits (e.g. email from a form)
  segment.identify(userId, traits, { context: appContext });
  // Posthog cannot handle undefined userId, but does have `setPersonProperties`.
  if (!userId) {
    posthog.setPersonProperties(traits);
  } else {
    posthog.identify(userId, traits);
  }
};

const page = (
  // Page call will include other helpful properties automatically like url, title, referrer.
  category?: string,
  name?: string,
  properties?: Record<string, any>,
  organizationId?: string,
) => {
  // TODO: Would be nice to auto-add the page name.
  properties = { ...properties, organization_id: organizationId };
  const context = { ...appContext, groupId: organizationId };
  LOGGING && console.info("page", category, name, properties);
  segment.page(category, name, properties, { context });
  posthog.capture("$pageview");
};

const track = (
  event: AnalyticsEvent,
  properties?: Record<string, any>,
  organizationId?: string,
) => {
  properties = { ...properties, organization_id: organizationId };
  const context = { ...appContext, groupId: organizationId };
  LOGGING && console.info("track", event, properties, context);
  segment.track(event, properties, { context });
  posthog.capture(event, { ...properties, ...context });
  // Google Tag Manager for Google Ads.
  // (I've lost track of how this work or why this is set up like so)
  TagManager.dataLayer({
    dataLayer: {
      event: "website_analytics",
      trackedEvent: event,
      ...properties,
    },
  });
};

export const reset = () => {
  LOGGING && console.info("reset");
  segment.reset();
  posthog.reset();
};

/**
 * Singleton class for analytics.
 *
 * This primarily exists to store the organizationId, so that it can be
 * inserted into all events so that we can group by organization.
 */
class Analytics {
  organizationId?: string;

  /**
   * Identify a user
   *
   * This should be called when the user logs in, and when the user signs up.
   *
   * This is also called on the backend, but we do it on the client to
   * join the anonymous user to their account. Unless there's frontend only
   * traits, we also don't need to worry about adding the traits here.
   *
   * @param userId `usr_...`
   * @param traits arbitrary key-value pairs that are attached to the user.
   *  I believe most destinations upsert on this collection.
   */
  public identify = async (
    userId?: string,
    traits?: any,
    organizationId?: string,
  ) => {
    if (!!organizationId) {
      this.organizationId = organizationId;
    }
    identify(userId, traits);
  };

  /**
   * Track a page view
   *
   * The page call will automatically track the path, title, url.
   * Can add in optional properties or override the others.
   *
   * See https://segment.com/docs/connections/spec/page/
   *
   * @param category (optional) – Type of page. I don't think we'll want this.
   *  More appropriate for a blog or ecommerce.
   * @param name (optional) - A canonical name for the page.
   * @param properties (optional)
   */
  public page = (
    category?: string,
    name?: string,
    properties?: Record<string, any>,
  ) => {
    page(category, name, properties, this.organizationId);
  };

  /**
   * Track an event
   *
   * Only track events that are client-side only and interesting for product analytics.
   * Most events should be sent from the backend.
   *
   * @param event An AnalyticsEvent name that follows our naming convention.
   * @param properties - All the properties that are relevant to the event.
   *
   */
  public track = (event: AnalyticsEvent, properties?: Record<string, any>) => {
    track(event, properties, this.organizationId);
  };

  /**
   * Reset the analytics state
   *
   * This should be called when the user logs out.
   */
  reset = () => {
    this.organizationId = undefined;
    reset();
  };
}

export const analytics = new Analytics();
